import React, {useEffect, useState} from 'react'
import api from '../util/api'
import Loader from "react-loader-spinner";
import {useHistory} from 'react-router-dom'


export default function GithubBoard() {

    const [job, setjob] = useState(undefined);
    const [searchquery, setSearchQuery ] = useState(undefined);

    let History = useHistory();
    const [formData, setFormData] = useState({ title: '', location: '' });
    const { title, location } = formData;

    const onChange = (e ) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

    const handler = async (e )=> {
        e.preventDefault();
        var payload = {title, location};
        var BaserUrl = "?description="+title+"&location="+location;
        History.push(BaserUrl);
        const dataset = await api.post('/api/job/find', payload);

        setSearchQuery(dataset.data);
       
    };


    useEffect(async ()=>{

            let data = await api.get('job/github');
            setjob(data.data);    
    },[])

    
    return (

        <div>
           
        <div className="container">
        <div>
        <br/>
        <h1 style={{fontWeight: '900'}} className="display-1"><span style={{color: '#2E7D32'}}>Find </span> your <span style={{color: '#FFD600'}}>Dream</span> Job</h1>
        <br/>
        <p className="lead" style={{color: '#c7006e'}}>Proudly Sponsoring Github Community</p>
        <br/>
        <br/>
        <br/>
        <form onSubmit={handler}>
                <div className="row">
                   <div className="col-xl-6 col-lg-6">
                   <input  style={{height: '47px'}} name="title" value={title} onChange={onChange} className="form-control" placeholder="Title, Description. e.g Software Engineering, Remote etc" type="text"/>
                   <br/>
                   </div>

                   <div className="col-xl-5 col-lg-5">
                   <input  style={{height: '47px'}} value={location} name="location" onChange={onChange} placeholder="Enter Location e.g Newyork, London, USA, UK  etc" className="form-control" type="text"/>
                   <br/>
                   </div>
                   <div className="col-xl-1 col-lg-1 text-right">
                   <button className="btn  btn-lg btn-outline-primary">Search</button>

                   </div>
                   </div>
                </form>
      
            
            <div>
            <div>
            <br/>
            <br/>
            <br/>
            <br/>
         
            </div>
        
            {searchquery !== undefined ? 
            <div className="row">
      
            {searchquery.length === 0 ? 
            <div>

            <h1 className="display-5">No Result Found</h1>
            <br/>
            <br/>
            </div> :
            
            <div><h1 className="display-5">Search Results</h1> </div>
            }
            
            {searchquery.map((ite)=>{
                return <div className="col-xl-3 col-md-4 col-lg-3 " style={{padding: '20px'}}> 
                <div class="card" >

                {ite.company_logo === null ?   <div className="text-center"><img class="card-img-top" style={{padding: '20px 0px', width: '50%'}} src="/not.svg" alt="Card image cap"/> </div> : 
                <div><img class="card-img-top text-center" style={{padding: '20px 0px'}} src={ite.company_logo} alt="Card image cap"/> </div>}
                
                <div class="card-body">
                    <h5 class="card-title">{ite.title}</h5>
                    <p class="card-text">{ite.company}</p>
                    <h6><img style={{width: '22px'}} src="/location.svg"/> &nbsp; {ite.location}</h6>
                  
                    <a href={"/job/github/search/"+ite.company+ "/"+ite.id}           
                     class="btn btn-info btn-lg text-white">Apply</a>
                </div>
                </div>

                          
                
                            
                </div>
            })}
             </div> : <div> 
             <div className="text-center">
            <div >
                 </div>
                        </div>
             </div>}
           







            {job !== undefined ? 
            <div className="row">
            
            <h1 className="display-6">Trending Jobs</h1>
            
            {job.map((ite)=>{
                return <div className="col-xl-3 col-md-4 col-lg-3 " style={{padding: '20px'}}> 
                <div class="card" >

                {ite.company_logo === null ?   <div className="text-center"><img class="card-img-top" style={{padding: '20px 0px', width: '50%'}} src="/not.svg" alt="Card image cap"/> </div> : 
                <div><img class="card-img-top text-center" style={{padding: '20px 0px'}} src={ite.company_logo} alt="Card image cap"/> </div>}
                
                <div class="card-body">
                    <h5 class="card-title">{ite.title}</h5>
                    <p class="card-text">{ite.company}</p>
                    <h6><img style={{width: '22px'}} src="/location.svg"/> &nbsp; {ite.location}</h6>
                  
                    <a href={"/jobs/github/"+ite.id}            
                     class="btn btn-info btn-lg text-white">Apply</a>
                </div>
                </div>

                          

                            
                </div>
            })}
             </div> : <div> 
             <div className="text-center">
            <div style={{marginTop: '20vh'}}>
                <Loader
                    type="Audio"
                    color="#C51162"
                    height={140}
                    width={140}
                    timeout={3000} //3 secs
                />    </div>
                        </div>
             </div>}
           
            

            </div>

            </div>
        </div>
        </div>
    )
}
