import React from 'react'
import ReactDOM from 'react-dom'
import GithubBoard from './github/GithubBoard'
import Routing from './route/Routing'

function Index() {
    return (
        <div>
        <Routing/>
        
            
        </div>
    )
}


ReactDOM.render(<Index/>, document.getElementById('root'));