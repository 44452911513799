import React, {useState, useEffect} from 'react'
import {useParams} from 'react-router-dom'
import api from '../util/api'
import Loader from "react-loader-spinner";



export default function SearchResult() {
    const [link, setLink] = useState(undefined);
    let {id, location} = useParams();

    useEffect(async () => {

        var newlocation = location;
        var jobid = id;

        var BaseUrl = "/jobs/github/" + newlocation +"/"+ jobid;
        let data = await api.post(BaseUrl);
       setLink(data.data);   
        
             
    }, [])

   
    if(link == undefined){

        return (
            <div className="text-center">
            <div style={{marginTop: '20vh'}}>
      <Loader
        type="Audio"
        color="#C51162"
        height={140}
        width={140}
        timeout={3000} //3 secs
      />    </div>
            </div>
        )
    }
    else{
        return (
            <div>
            <div className="container">
            <h1 className="display-4">{link[0].title}</h1>
            <br/>
            <br/>
            <h2><img style={{width: '34px'}} src="/location.svg"/> &nbsp; {link[0].location}</h2>
            <br/>

            <div className="row">
                <div className="col-xl-3">
                {link[0].company_logo === null ?   <div className=""><img class="card-img-top" style={{padding: '20px 0px', width: '70%'}} src="/not.svg" alt="Card image cap"/> </div> : 
                <div><img class="" style={{padding: '20px 0px', width: '70%'}} src={link[0].company_logo} alt="Card image cap"/> </div>}
                <br/>
                <br/>
                <a className="btn btn-lg btn-info text-white" href={link[0].url}>Apply Now</a>
                </div>
                <div className="col-xl-9">
                <div className="lead" dangerouslySetInnerHTML={{__html: link[0].description}}></div>
    
            <div className="lead alert alert-info " dangerouslySetInnerHTML={{__html: link[0].how_to_apply}}></div>

                </div>
            </div>
           
            
            </div>
                
            </div>
        )
    }


   
}
