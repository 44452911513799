import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link, 
    useHistory
  } from "react-router-dom";
  import React, {Fragment} from 'react'
  import GithubBoard from '../github/GithubBoard'
  import JobBoard from '../github/JobBoard'
  import Navbar from '../component/Navbar'
  import SearchResult from '../github/SearchResult'



  export default function Routing (){
      return(
        <Router>
        <Fragment>   
        <Navbar/>    
          <Switch>    
          <Route  exact path="/" component={Home} /> 
            <Route  exact path="/jobs/github/" component={GithubBoard} />      
            <Route  exact path="/jobs/github/:id" component={JobBoard} />    
            <Route  exact path="/job/github/search/:location/:id" component={SearchResult} />         
            
          </Switch>
        </Fragment>
      </Router>  
      )
  }

  function Home(){

    let History = useHistory();
    
    History.push('/jobs/github')

      return(
          <div className="text-center">
            <h1 className="display-1">Under Construction</h1>
            <br/>
            <br/>
            <a href="/jobs/github" className="btn btn-primary btn-lg">Browse Jobs</a>
          </div>
      )
  }

